<template>
  <v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-data-table
      id="tech-user-table"
      v-model="selected"
      :headers="tech_user.headers"
      :items="tech_user.tUsers"
      :search="searchTable"
      class="elevation-1"
      show-select
      item-key="username"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>API USERS</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="searchTable"
            techend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <v-spacer />
          <template>
            <v-btn
              color="primary"
              width="50"
              @click="_switchTUState(selected)"
            >
              State
            </v-btn>
          </template>
          <v-spacer />
          <template>
            <cu-tech-user-dialog
              :is-button="true"
              @toTechUserTable="eventFromDialog"
            />
          </template>
        </v-toolbar>
      </template>
      <template v-slot:[`item._state`]="{ item }">
        <v-chip
          :color="item._state === true ? 'color_green' : '#C70039'"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <cu-tech-user-dialog
          :form="item"
          @toTechUserTable="eventFromDialog"
        />
        <delete
          :id="item.username"
          @deleted="deleteItem(item.username)"
        />
      </template>
      <template v-slot:no-data>
        <span color="primary"> No API users yet. Add some! </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    cuTechUserDialog: () =>
      import('@/components/customer/tech-user/CUTechUserDialog'),
    delete: () => import('@/components/utils/Delete')
  },

  data() {
    return {
      overlay: false,
      searchTable: '',
      searchPolicy: '',
      selected: []
    };
  },

  computed: {
    ...mapState(['tech_user'])
  },

  methods: {
    ...mapActions(['switchTUState', 'showPolicyDialog', 'deleteTableTechUser']),
    eventFromDialog() {
      this.item_form = {
        username: '',
        email: '',
        policy_id: 0
      };
    },
    async _switchTUState(selected) {
      this.overlay = true;
      await this.switchTUState(selected);
      this.overlay = false;
    },
    deleteItem(username) {
      this.deleteTableTechUser(username);
    }
  }
};
</script>
